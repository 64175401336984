<template>
  <div class="details">
    <Details :crumbsConfig="crumbsConfig"/>
  </div>
</template>

<script>
export default {
  name: "industryClassDetails",
  data() {
    return {
      crumbsConfig: [
        {
          url: "/",
          text: "首页"
        },
        {
          url: "/industryClass/list",
          text: "产业课堂"
        },
        {
          text: "课程详情"
        }
      ]
    };
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px) {
}
</style>
